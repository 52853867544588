.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#focus, #seeking{
  display: flex;
  justify-content: space-evenly;
  margin: 5% auto;
  width: 80%;
}

body{
  background-color: #BF8E2A;
}

#container{
  background-color: #815C11;
  width: 80%;
  margin: 2% auto;
  padding: 5%;
  border: black 2px solid;
}


nav{
  display: flex;
  justify-content: space-evenly;
  margin: 0.5% 0;
}

nav a{
  text-decoration: none;
  color: black;
  background-color: #815C11;
  padding: 0.5% 1%;
  border-radius: 50%;
  border: 1px black solid;
}

.box {
  background-color: #815C11;
  width: 40%;
  margin: 2% auto;
  padding: 5%;
  border: black 2px solid;
}

.auth {
  background-color: #815C11;
  width: 50%;
  margin: 2% auto;
  padding: 5%;
  border: black 2px solid;
}

.form {
  background-color: #815C11;
  width: 80%;
  margin: 2% auto;
  padding: 5%;
  border: black 2px solid;
  display: flex;
  flex-direction: column;
}

.form input, label, button{
  width: 30%;
  margin: 0.5% auto;
}

.form button {
  margin-top: 2%;
}

button {
  display: block;
}

.motm{
  background-color: #815C11;
  width: fit-content;
  margin: 2% auto;
  padding: 1%;
  border: 1px solid black;
  border-radius: 100rem;
}